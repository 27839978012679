import {
  SupplierRegistrationProcessFormType,
  translateExpectedPaymentsExceedThresholdAnswer,
} from '@app/domain/commonSupplierRegistration';
import {
  getRecordType,
  LyonsResponseMatch,
  ShouldSendInstComponentTypes,
  SupplierRegistrationProcess,
  SurepayResponseMatch,
} from '@mortee/domain/morteeRegistrationForms';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import CategoryAndValues from '@app/components/CategoryAndValues';
import { getCountryName } from '@app/domain/countries';
import { observer } from 'mobx-react';
import Button from '@app/components/Button';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import { formatInternationalPhoneNumberForDisplay } from '@app/utils/phoneUtils';
import {
  BodyRegularStartTransparentBlack400,
  Regular22TransparentBlack900,
  Regular24TransparentBlack400,
} from '@app/components/Text';
import { SpecialInfoComponentType } from '@app/components/SpecialInfoComponents';
import useInfraStores from '@app/hooks/useInfraStores';
import supplierRegistrationProcessIcon from '@mortee/images/validationSystem/supplierRegistrationProcessIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import ModalAppContext, { ModalAppContextProps } from '@app/ModalAppContext';
import { showContentOnlyModal } from '@app/components/Modal';
import SVG from '@app/components/SVG';
import EyeOffImage from '@app/images/ic-eye-off.svg';
import CopyableValue from '@app/components/CopyableValue';
import { getInstructionType } from '@mortee/domain/validationSystemInfoComponents';
import {
  copyAllRegistrationFormData,
  copyWiRegistrationFormData,
  downloadCrowdKnowledgeReportCsv,
} from '@mortee/domain/morteeRegistrationFormsActions';

interface Props {
  item: SupplierRegistrationProcess;
  onDone: () => void;
}

const SupplierRegistrationViewModal: FC<Props> = observer(({ item, onDone }) => {
  const { localeStore } = useInfraStores();

  const expectedPaymentsExceedThresholdFormatter = (item: SupplierRegistrationProcess): string | null => {
    if (
      !item.expectedPaymentsExceedThreshold ||
      !item.configurationData?.futurePaymentsThresholdAmount ||
      !item.configurationData?.futurePaymentsThresholdCurrency
    ) {
      return null;
    }

    const amountWithCurrency = localeStore.formatCurrency(
      item.configurationData.futurePaymentsThresholdAmount,
      item.configurationData.futurePaymentsThresholdCurrency,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currencyDisplay: 'narrowSymbol',
      },
    );

    return `${translateExpectedPaymentsExceedThresholdAnswer(item.expectedPaymentsExceedThreshold)}/${amountWithCurrency}`;
  };

  const getRelatedIds = (item: SupplierRegistrationProcess): any => {
    if (item.supplierValidationRelatedValidatedPayeesInfo?.knoxIds?.length) {
      return item.supplierValidationRelatedValidatedPayeesInfo?.knoxIds;
    } else if (item.supplierValidationRelatedValidatedPayeesInfo?.legalEntityIdentifiers?.length) {
      return item.supplierValidationRelatedValidatedPayeesInfo?.legalEntityIdentifiers;
    }

    return null;
  };

  const extractValuesList = (
    item: SupplierRegistrationProcess,
  ): Record<string, string | null | string[] | SpecialInfoComponentType> => {
    switch (item.type) {
      case SupplierRegistrationProcessFormType.regularSupplierValidation: {
        return {
          'Is async process': 'False',
          'Time of registration': localeStore.formatDateTime(item.writeTimestamp),
          'Form Type': getRecordType(item.type),
          'Registration number': item.registrationNumber,
          'Referring customer': item.referringCustomer,
          ...getObjectOfValueOrNothing('Instructions', getInstructionType(item.instructionType) ?? null),
          'Should send instructions': ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier],
          'Tax ID type': item.legalIdType,
          'Tax ID value': item.legalId,
          'Full Address': item.address.addressDetails?.formattedAddress || item.address.fullAddress,
          ...getObjectOfValueOrNothing('Address', item.address.addressDetails?.address ?? null),
          ...getObjectOfValueOrNothing('Address City', item.address.addressDetails?.city ?? null),
          ...getObjectOfValueOrNothing('Address State', item.address.addressDetails?.state ?? null),
          ...getObjectOfValueOrNothing('Address Zip Code', item.address.addressDetails?.zipCode ?? null),
          ...getObjectOfValueOrNothing('Address Country', item.address.addressDetails?.countryCode ?? null),
          Country: getCountryName(item.countryCode),
          Swift: item.accountDetails.swiftCode,
          'Bank / Routing / Sort / Institution Number': item.accountDetails.bankCode,
          'Branch / Transit Number': item.accountDetails.branchCode,
          'Account number / IBAN': item.accountDetails.accountNumber,
          'Fedwire ABA': item.abaRouting,
          ...getObjectOfValueOrNothing('Can Use ACH?', item.canUseAch?.toString() ?? null),
          ...getObjectOfValueOrNothing('Can Use EFT?', item.canUseEft?.toString() ?? null),
          'Account bank country': getCountryName(item.accountBankCountryCode),
          ...getObjectOfValueOrNothing('Further credit', item.furtherCredit),
          ...getObjectOfValueOrNothing('Currency', item.currency),
          ...getObjectOfValueOrNothing('Contact', item.fullName),
          ...getObjectOfValueOrNothing('Phone number', formatInternationalPhoneNumberForDisplay(item.phoneNumber)),
          ...getObjectOfValueOrNothing('Mail', item.email),
          ...getObjectOfValueOrNothing('Customer IP', item.customerIp),
          ...getObjectOfValueOrNothing('Website', item.companyWebsite),
          ...getObjectOfValueOrNothing('Related vendors for check', getRelatedIds(item)),
          ...getObjectOfValueOrNothing('Date of birth', item.dateOfBirth ? localeStore.formatDateTime(item.dateOfBirth) : null),
          ...getObjectOfValueOrNothing('Account type', item.accountType),
          ...getObjectOfValueOrNothing('Registration ID', item.registrationId),
          ...getObjectOfValueOrNothing('Bank name', item.bankName),
          ...getObjectOfValueOrNothing(
            'Lyons response',
            item.lyonsResponseMatch ? LyonsResponseMatchDisplay[item.lyonsResponseMatch] : null,
          ),
          ...getObjectOfValueOrNothing(
            'Surepay response',
            item.surepayResponseMatch ? SurepayResponseMatchDisplay[item.surepayResponseMatch] : null,
          ),
        };
      }
      case SupplierRegistrationProcessFormType.asyncSupplierValidation: {
        return {
          'Is async process': 'True',
          'Time of registration': localeStore.formatDateTime(item.writeTimestamp),
          'Form Type': getRecordType(item.type),
          'Registration number': item.registrationNumber,
          'Referring customer': item.referringCustomer,
          ...getObjectOfValueOrNothing('Instructions', getInstructionType(item.instructionType) ?? null),
          'Should send instructions': ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier],
          'Tax ID type': item.legalIdType,
          'Tax ID value': item.legalId,
          'Full Address': item.address.addressDetails?.formattedAddress || item.address.fullAddress,
          ...getObjectOfValueOrNothing('Address', item.address.addressDetails?.address ?? null),
          ...getObjectOfValueOrNothing('Address City', item.address.addressDetails?.city ?? null),
          ...getObjectOfValueOrNothing('Address State', item.address.addressDetails?.state ?? null),
          ...getObjectOfValueOrNothing('Address Zip Code', item.address.addressDetails?.zipCode ?? null),
          ...getObjectOfValueOrNothing('Address Country', item.address.addressDetails?.countryCode ?? null),
          Country: getCountryName(item.countryCode),
          Swift: item.accountDetails.swiftCode,
          'Bank / Routing / Sort / Institution Number': item.accountDetails.bankCode,
          'Branch / Transit Number': item.accountDetails.branchCode,
          'Account number': item.accountDetails.accountNumber,
          IBAN: item.accountDetails.iban,
          'Fedwire ABA': item.abaRouting,
          ...getObjectOfValueOrNothing('Can Use ACH?', item.canUseAch?.toString() ?? null),
          ...getObjectOfValueOrNothing('Can Use EFT?', item.canUseEft?.toString() ?? null),
          'Account bank country': getCountryName(item.accountBankCountryCode),
          ...getObjectOfValueOrNothing('Further credit', item.furtherCredit),
          ...getObjectOfValueOrNothing('Currency', item.currency),
          ...getObjectOfValueOrNothing('Contact', item.fullName),
          ...getObjectOfValueOrNothing('Phone number', formatInternationalPhoneNumberForDisplay(item.phoneNumber)),
          ...getObjectOfValueOrNothing('Mail', item.email),
          ...getObjectOfValueOrNothing('Customer IP', item.customerIp),
          ...getObjectOfValueOrNothing('Website', item.companyWebsite),
          ...getObjectOfValueOrNothing('Related vendors for check', getRelatedIds(item)),
          ...getObjectOfValueOrNothing('Date of birth', item.dateOfBirth ? localeStore.formatDateTime(item.dateOfBirth) : null),
          ...getObjectOfValueOrNothing('Account type', item.accountType),
          ...getObjectOfValueOrNothing('Registration ID', item.registrationId),
          ...getObjectOfValueOrNothing('Bank name', item.bankName),
          ...getObjectOfValueOrNothing(
            'Lyons response',
            item.lyonsResponseMatch ? LyonsResponseMatchDisplay[item.lyonsResponseMatch] : null,
          ),
          ...getObjectOfValueOrNothing(
            'Surepay response',
            item.surepayResponseMatch ? SurepayResponseMatchDisplay[item.surepayResponseMatch] : null,
          ),
        };
      }
      //Deprecated
      case SupplierRegistrationProcessFormType.dynamicSupplierValidation: {
        return {
          'Time of registration': localeStore.formatDateTime(item.writeTimestamp),
          'Form Type': getRecordType(item.type),
          'Registration number': item.registrationNumber,
          'Referring customer': item.referringCustomer,
          'Tax ID type': item.legalIdType,
          'Tax ID value': item.legalId,
          'Full Address': item.address.addressDetails?.formattedAddress || item.address.fullAddress,
          ...getObjectOfValueOrNothing('Address', item.address.addressDetails?.address ?? null),
          ...getObjectOfValueOrNothing('Address City', item.address.addressDetails?.city ?? null),
          ...getObjectOfValueOrNothing('Address State', item.address.addressDetails?.state ?? null),
          ...getObjectOfValueOrNothing('Address Zip Code', item.address.addressDetails?.zipCode ?? null),
          ...getObjectOfValueOrNothing('Address Country', item.address.addressDetails?.countryCode ?? null),
          Country: getCountryName(item.countryCode),
          Swift: item.accountDetails.swiftCode,
          'Bank / Routing / Sort / Institution Number': item.accountDetails.bankCode,
          'Branch / Transit Number': item.accountDetails.branchCode,
          'Account number / IBAN': item.accountDetails.accountNumber,
          'Fedwire ABA': item.abaRouting,
          ...getObjectOfValueOrNothing('Can Use ACH?', item.canUseAch?.toString() ?? null),
          ...getObjectOfValueOrNothing('Can Use EFT?', item.canUseEft?.toString() ?? null),
          'Account bank country': getCountryName(item.accountBankCountryCode),
          ...getObjectOfValueOrNothing('Further credit', item.furtherCredit),
          ...getObjectOfValueOrNothing('Currency', item.currency),
          ...getObjectOfValueOrNothing('Contact', item.fullName),
          ...getObjectOfValueOrNothing('Phone number', formatInternationalPhoneNumberForDisplay(item.phoneNumber)),
          ...getObjectOfValueOrNothing('Mail', item.email),
          ...getObjectOfValueOrNothing('Customer IP', item.customerIp),
          ...getObjectOfValueOrNothing('Website', item.companyWebsite),
          ...getObjectOfValueOrNothing('Future payments', expectedPaymentsExceedThresholdFormatter(item)),
          ...getObjectOfValueOrNothing('Related vendors for check', getRelatedIds(item)),
          ...getObjectOfValueOrNothing('Registration ID', item.registrationId),
          ...getObjectOfValueOrNothing(
            'Lyons response',
            item.lyonsResponseMatch ? LyonsResponseMatchDisplay[item.lyonsResponseMatch] : null,
          ),
          ...getObjectOfValueOrNothing(
            'Surepay response',
            item.surepayResponseMatch ? SurepayResponseMatchDisplay[item.surepayResponseMatch] : null,
          ),
        };
      }
      case SupplierRegistrationProcessFormType.incomingPaymentSecurity: {
        return {
          'Time of registration': localeStore.formatDateTime(item.writeTimestamp),
          'Form Type': getRecordType(item.type),
          'Registration number': item.registrationNumber,
          ...getObjectOfValueOrNothing('Instructions', getInstructionType(item.instructionType) ?? null),
          'Should send instructions': ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier],
          'Tax ID type': item.legalIdType,
          'Tax ID value': item.legalId,
          'Full Address': item.address.addressDetails?.formattedAddress || item.address.fullAddress,
          ...getObjectOfValueOrNothing('Address', item.address.addressDetails?.address ?? null),
          ...getObjectOfValueOrNothing('Address City', item.address.addressDetails?.city ?? null),
          ...getObjectOfValueOrNothing('Address State', item.address.addressDetails?.state ?? null),
          ...getObjectOfValueOrNothing('Address Zip Code', item.address.addressDetails?.zipCode ?? null),
          ...getObjectOfValueOrNothing('Address Country', item.address.addressDetails?.countryCode ?? null),
          Country: getCountryName(item.countryCode),
          Swift: item.accountDetails.swiftCode,
          'Bank / Routing / Sort / Institution Number': item.accountDetails.bankCode,
          'Branch / Transit Number': item.accountDetails.branchCode,
          'Account number / IBAN': item.accountDetails.accountNumber,
          'Fedwire ABA': item.abaRouting,
          'Account bank country': getCountryName(item.accountBankCountryCode),
          ...getObjectOfValueOrNothing('Further credit', item.furtherCredit),
          ...getObjectOfValueOrNothing('Currency', item.currency),
          ...getObjectOfValueOrNothing('Contact', item.fullName),
          ...getObjectOfValueOrNothing('Phone number', formatInternationalPhoneNumberForDisplay(item.phoneNumber)),
          ...getObjectOfValueOrNothing('Mail', item.email),
          ...getObjectOfValueOrNothing('Customer IP', item.customerIp),
          ...getObjectOfValueOrNothing('Website', item.companyWebsite),
          ...getObjectOfValueOrNothing('Date of birth', item.dateOfBirth ? localeStore.formatDateTime(item.dateOfBirth) : null),
          ...getObjectOfValueOrNothing('Account type', item.accountType),
          ...getObjectOfValueOrNothing('Registration ID', item.registrationId),
          ...getObjectOfValueOrNothing('Bank name', item.bankName),
          ...getObjectOfValueOrNothing(
            'Lyons result',
            item.lyonsResponseMatch ? LyonsResponseMatchDisplay[item.lyonsResponseMatch] : null,
          ),
          ...getObjectOfValueOrNothing(
            'Surepay response',
            item.surepayResponseMatch ? SurepayResponseMatchDisplay[item.surepayResponseMatch] : null,
          ),
        };
      }
      case SupplierRegistrationProcessFormType.externalSupplierValidation: {
        return {
          'Time of registration': localeStore.formatDateTime(item.writeTimestamp),
          'Form Type': getRecordType(item.type),
          'Registration number': item.registrationNumber,
          ...getObjectOfValueOrNothing('Instructions', getInstructionType(item.instructionType) ?? null),
          'Should send instructions': ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier],
          'Tax ID type': item.legalIdType,
          'Tax ID value': item.legalId,
          'Full Address': item.address.addressDetails?.formattedAddress || item.address.fullAddress,
          ...getObjectOfValueOrNothing('Address', item.address.addressDetails?.address ?? null),
          ...getObjectOfValueOrNothing('Address City', item.address.addressDetails?.city ?? null),
          ...getObjectOfValueOrNothing('Address State', item.address.addressDetails?.state ?? null),
          ...getObjectOfValueOrNothing('Address Zip Code', item.address.addressDetails?.zipCode ?? null),
          ...getObjectOfValueOrNothing('Address Country', item.address.addressDetails?.countryCode ?? null),
          Country: getCountryName(item.countryCode),
          Swift: item.accountDetails.swiftCode,
          'Bank / Routing / Sort / Institution Number': item.accountDetails.bankCode,
          'Branch / Transit Number': item.accountDetails.branchCode,
          'Account number': item.accountDetails.accountNumber,
          IBAN: item.accountDetails.iban,
          'Fedwire ABA': item.abaRouting,
          ...getObjectOfValueOrNothing('Can Use ACH?', item.canUseAch?.toString() ?? null),
          ...getObjectOfValueOrNothing('Can Use EFT?', item.canUseEft?.toString() ?? null),
          'Account bank country': getCountryName(item.accountBankCountryCode),
          ...getObjectOfValueOrNothing('Further credit', item.furtherCredit),
          ...getObjectOfValueOrNothing('Currency', item.currency),
          ...getObjectOfValueOrNothing('Contact', item.fullName),
          ...getObjectOfValueOrNothing('Phone number', formatInternationalPhoneNumberForDisplay(item.phoneNumber)),
          ...getObjectOfValueOrNothing('Mail', item.email),
          ...getObjectOfValueOrNothing('Customer IP', item.customerIp),
          ...getObjectOfValueOrNothing('Website', item.companyWebsite),
          ...getObjectOfValueOrNothing('Related vendors for check', getRelatedIds(item)),
          ...getObjectOfValueOrNothing('Date of birth', item.dateOfBirth ? localeStore.formatDateTime(item.dateOfBirth) : null),
          ...getObjectOfValueOrNothing('Account type', item.accountType),
          ...getObjectOfValueOrNothing('Registration ID', item.registrationId),
          ...getObjectOfValueOrNothing('Bank name', item.bankName),
          ...getObjectOfValueOrNothing(
            'Lyons result',
            item.lyonsResponseMatch ? LyonsResponseMatchDisplay[item.lyonsResponseMatch] : null,
          ),
          ...getObjectOfValueOrNothing(
            'Surepay response',
            item.surepayResponseMatch ? SurepayResponseMatchDisplay[item.surepayResponseMatch] : null,
          ),
        };
      }
    }
  };

  const LyonsResponseMatchDisplay = {
    [LyonsResponseMatch.match]: 'Matched',
    [LyonsResponseMatch.noMatch]: 'No Match',
    [LyonsResponseMatch.matchWithAlert]: 'Matched with alert',
    [LyonsResponseMatch.closeMatch]: 'Close Match',
  };

  const SurepayResponseMatchDisplay = {
    [SurepayResponseMatch.match]: 'Matched',
    [SurepayResponseMatch.noMatch]: 'No Match',
    [SurepayResponseMatch.partialMatch]: 'Partial Match',
    [SurepayResponseMatch.invalid]: 'Invalid Information',
  };

  function getObjectOfValueOrNothing<T>(key: string, value: T): { [p: string]: T } | undefined {
    if (value) {
      return { [key]: value };
    }
  }

  const keyAndValue = extractValuesList(item);

  return (
    <MainContainer data-testid='supplier-registration-view-modal'>
      <Title>
        <StyledTitleText>
          <TitleIconsAndTextContainer>
            {item.hidden && <SVG width={24} height={24} image={EyeOffImage} accessibilityLabel='Hidden Record' />}
            <IconAndText image={supplierRegistrationProcessIcon} iconAccessibilityLabel='supplier registration process icon'>
              <CopyableValue dataTestId='companyName-copyable' label='Company name' value={item.companyName} />
            </IconAndText>
          </TitleIconsAndTextContainer>
          {item.additionalCompanyName && (
            <BodyRegularStartTransparentBlack400.div>
              <CopyableValue
                dataTestId='supp-vald-mang-modal-additional-company-name'
                label='Company English name'
                value={item.additionalCompanyName}
              >
                (English name: {item.additionalCompanyName})
              </CopyableValue>
            </BodyRegularStartTransparentBlack400.div>
          )}
        </StyledTitleText>
      </Title>
      <StyledActionsButtonsContainer>
        <Button id='supp-vald-mang-modal-copy-wi-btn' onClick={(): void => copyWiRegistrationFormData(item)}>
          Copy WI
        </Button>
        {!!item.configurationData && (
          <Button
            id='supp-vald-mang-modal-copy-all-data-btn'
            onClick={(): void => copyAllRegistrationFormData(item, localeStore)}
          >
            Copy ALL
          </Button>
        )}
        {item.doesHaveCrowdKnowledgeCandidate && (
          <Button
            id='supp-vald-mang-modal-download-ck-candidates-btn'
            onClick={(): Promise<void> => downloadCrowdKnowledgeReportCsv(item)}
          >
            Download CK
          </Button>
        )}
      </StyledActionsButtonsContainer>
      {item.type !== SupplierRegistrationProcessFormType.incomingPaymentSecurity && (
        <RegistrationTypeDisplay>{item.isIndividual ? 'Individual' : 'Company'}</RegistrationTypeDisplay>
      )}
      {Object.entries(keyAndValue).map(([label, value]) => (
        <CategoryAndValues key={label} inlineLabel label={label} className={WRAP_WORDS_CSS_PROPERTIES}>
          {value && <CopyableValue dataTestId={`${label}-copyable`} label={label} value={value} />}
        </CategoryAndValues>
      ))}
      <ActionsContainer>
        <Button id='supp-vald-mang-modal-done' onClick={onDone}>
          DONE
        </Button>
      </ActionsContainer>
    </MainContainer>
  );
});

export default SupplierRegistrationViewModal;

export function openSupplierRegistrationAllDataModal(
  item: SupplierRegistrationProcess,
  modalContext: ModalAppContextProps,
): void {
  showContentOnlyModal(
    (onDone) => (
      <ModalAppContext {...modalContext}>
        <SupplierRegistrationViewModal item={item} onDone={onDone} />
      </ModalAppContext>
    ),
    {
      okText: 'DONE',
      className: 'card',
    },
  );
}

const MainContainer = styled.div`
  min-width: 550px;
  max-width: 95vw;
  padding: 32px 32px 16px;

  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }

  --hover-color: var(--accent-blue-600);
`;

const Title = styled.div`
  display: flex;
  align-items: flex-start;
`;

const StyledTitleText = styled(Regular24TransparentBlack400.div)`
  flex: 1;
  margin-bottom: 16px;
`;

const RegistrationTypeDisplay = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--black-strong);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const StyledActionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: -15px;
`;

const TitleIconsAndTextContainer = styled.div`
  ${Regular22TransparentBlack900.css};
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
