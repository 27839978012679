import memoize from 'fast-memoize';
import { TFunction } from 'react-i18next';
import { maxByKey } from '@app/utils/arrayUtils';

import { compare } from '@app/utils/comparatorUtils';

export interface Country {
  name: string;
  dialCode: number;
  priority?: boolean;
}

export const getCountryCodeByDialCode = (dialCode: string | null): string | undefined => {
  if (!dialCode) {
    return undefined;
  }

  const numberDialCode = parseInt(dialCode);

  const foundCountry = maxByKey(
    Object.entries(COUNTRIES).filter(([, country]) => country.dialCode == numberDialCode),
    ([, country]): boolean | undefined => country.priority,
    compare.booleans().reverse(),
  );

  return foundCountry ? foundCountry[0] : undefined;
};

export const getCountryName = (countryCode: string | null | undefined, unknownCountryText: string = 'Unknown'): string => {
  if (countryCode) {
    return getCountryByCountryCode(countryCode)?.name ?? countryCode;
  }

  return unknownCountryText;
};

export const getCountryByCountryCode = (countryCode: string | null | undefined): Country | undefined => {
  if (!countryCode) {
    return;
  }

  return COUNTRIES[countryCode.toUpperCase()];
};

export const getDialCodeByCountryCode = (countryCode: string | null | undefined): number | undefined => {
  const country = getCountryByCountryCode(countryCode);
  if (!country) {
    return;
  }

  return country.dialCode;
};

export const getCountryCodeFromCountry = (country: Country): string | undefined => {
  if (!country) {
    return;
  }
  return Object.keys(COUNTRIES).find((key) => COUNTRIES[key].name === country.name);
};

export const getCountriesOrder = memoize(
  (language?: string, translationMethod?: TFunction): string[] => {
    if (!language || !translationMethod) {
      return Object.keys(COUNTRIES);
    }

    return Object.entries(COUNTRIES)
      .sort(compare.byStringField((countryWithCode) => translationMethod(countryWithCode[1].name)))
      .map(([countryCode]) => countryCode);
  },
  {
    serializer: ([languageParam]) => languageParam ?? '',
  },
);

export const getCountriesOrderByCountryCode = memoize((): string[] =>
  Object.keys(COUNTRIES).sort(compare.stringsCaseInsensitive()),
);

export enum COUNTRY_CODES {
  Afghanistan = 'AF',
  AlandIslands = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  AmericanSamoa = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  Anguilla = 'AI',
  Antarctica = 'AQ',
  AntiguaAndBarbuda = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  Bolivia = 'BO',
  BosniaAndHerzegovina = 'BA',
  Botswana = 'BW',
  BouvetIsland = 'BV',
  Brazil = 'BR',
  BritishIndianOceanTerritory = 'IO',
  BruneiDarussalam = 'BN',
  Bulgaria = 'BG',
  BurkinaFaso = 'BF',
  Burundi = 'BI',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  CapeVerde = 'CV',
  CaymanIslands = 'KY',
  CentralAfricanRepublic = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  ChristmasIsland = 'CX',
  CocosKeelingIslands = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  TheDemocraticRepublicOfTheCongo = 'CD',
  CookIslands = 'CK',
  CostaRica = 'CR',
  CotedIvoire = 'CI',
  Croatia = 'HR',
  Cuba = 'CU',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  ElSalvador = 'SV',
  EquatorialGuinea = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  FalklandIslands = 'FK',
  FaroeIslands = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  FrenchGuiana = 'GF',
  FrenchPolynesia = 'PF',
  FrenchSouthernTerritories = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  GuineaBissau = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  HeardIslandAndMcdonaldIslands = 'HM',
  VaticanCityState = 'VA',
  Honduras = 'HN',
  HongKong = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  Iran = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  IsleOfMan = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  NorthKorea = 'KP',
  RepublicOfSouthKorea = 'KR',
  Kosovo = 'XK',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  Laos = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  LibyanArabJamahiriya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  Macedonia = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  MarshallIslands = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  Micronesia = 'FM',
  Moldova = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  NewCaledonia = 'NC',
  NewZealand = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  NorfolkIsland = 'NF',
  NorthernMarianaIslands = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  PalestinianTerritory = 'PS',
  Panama = 'PA',
  PapuaNewGuinea = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Pitcairn = 'PN',
  Poland = 'PL',
  Portugal = 'PT',
  PuertoRico = 'PR',
  Qatar = 'QA',
  Romania = 'RO',
  Russia = 'RU',
  Rwanda = 'RW',
  Reunion = 'RE',
  SaintBarthelemy = 'BL',
  SaintHelena = 'SH',
  SaintKittsandNevis = 'KN',
  SaintLucia = 'LC',
  SaintMartin = 'MF',
  SintMaarten = 'SX',
  SaintPierreAndMiquelon = 'PM',
  SaintVincentAndTheGrenadines = 'VC',
  Samoa = 'WS',
  SanMarino = 'SM',
  SaoTomeandPrincipe = 'ST',
  SaudiArabia = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  SierraLeone = 'SL',
  Singapore = 'SG',
  Slovakia = 'SK',
  Slovenia = 'SI',
  SolomonIslands = 'SB',
  Somalia = 'SO',
  SouthAfrica = 'ZA',
  SouthSudan = 'SS',
  SouthGeorgiaAndTheSouthSandwichIslands = 'GS',
  Spain = 'ES',
  SriLanka = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  SvalbardAndJanMayen = 'SJ',
  Swaziland = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  SyrianArabRepublic = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  TimorLeste = 'TL',
  Togo = 'TG',
  Tokelau = 'TK',
  Tonga = 'TO',
  TrinidadAndTobago = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  TurksAndCaicosIslands = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  UnitedArabEmirates = 'AE',
  UnitedKingdom = 'GB',
  UnitedStates = 'US',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  Vietnam = 'VN',
  VirginIslandsBritish = 'VG',
  VirginIslandsUS = 'VI',
  WallisAndFutuna = 'WF',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
}

export type CountryCode = ValuesOfType<typeof COUNTRY_CODES>;

export type TranslatedCountry = {
  code: string;
  translatedName: string;
};

const COUNTRIES: Record<CountryCode, Country> = {
  AF: { name: 'Afghanistan', dialCode: 93 },
  AX: { name: 'Åland Islands', dialCode: 358 },
  AL: { name: 'Albania', dialCode: 355 },
  DZ: { name: 'Algeria', dialCode: 213 },
  AS: { name: 'American Samoa', dialCode: 1684 },
  AD: { name: 'Andorra', dialCode: 376 },
  AO: { name: 'Angola', dialCode: 244 },
  AI: { name: 'Anguilla', dialCode: 1264 },
  AQ: { name: 'Antarctica', dialCode: 672, priority: true },
  AG: { name: 'Antigua and Barbuda', dialCode: 1268 },
  AR: { name: 'Argentina', dialCode: 54 },
  AM: { name: 'Armenia', dialCode: 374 },
  AW: { name: 'Aruba', dialCode: 297 },
  AU: { name: 'Australia', dialCode: 61, priority: true },
  AT: { name: 'Austria', dialCode: 43 },
  AZ: { name: 'Azerbaijan', dialCode: 994 },
  BS: { name: 'Bahamas', dialCode: 1242 },
  BH: { name: 'Bahrain', dialCode: 973 },
  BD: { name: 'Bangladesh', dialCode: 880 },
  BB: { name: 'Barbados', dialCode: 1246 },
  BY: { name: 'Belarus', dialCode: 375 },
  BE: { name: 'Belgium', dialCode: 32 },
  BZ: { name: 'Belize', dialCode: 501 },
  BJ: { name: 'Benin', dialCode: 229 },
  BM: { name: 'Bermuda', dialCode: 1441 },
  BT: { name: 'Bhutan', dialCode: 975 },
  BO: { name: 'Bolivia, Plurinational State of bolivia', dialCode: 591 },
  BA: { name: 'Bosnia and Herzegovina', dialCode: 387 },
  BW: { name: 'Botswana', dialCode: 267 },
  BV: { name: 'Bouvet Island', dialCode: 47 },
  BR: { name: 'Brazil', dialCode: 55 },
  IO: { name: 'British Indian Ocean Territory', dialCode: 246 },
  BN: { name: 'Brunei Darussalam', dialCode: 673 },
  BG: { name: 'Bulgaria', dialCode: 359 },
  BF: { name: 'Burkina Faso', dialCode: 226 },
  BI: { name: 'Burundi', dialCode: 257 },
  KH: { name: 'Cambodia', dialCode: 855 },
  CM: { name: 'Cameroon', dialCode: 237 },
  CA: { name: 'Canada', dialCode: 1 },
  CV: { name: 'Cape Verde', dialCode: 238 },
  KY: { name: 'Cayman Islands', dialCode: 1345 },
  CF: { name: 'Central African Republic', dialCode: 236 },
  TD: { name: 'Chad', dialCode: 235 },
  CL: { name: 'Chile', dialCode: 56 },
  CN: { name: 'China', dialCode: 86 },
  CX: { name: 'Christmas Island', dialCode: 61 },
  CC: { name: 'Cocos (Keeling) Islands', dialCode: 61 },
  CO: { name: 'Colombia', dialCode: 57 },
  KM: { name: 'Comoros', dialCode: 269 },
  CG: { name: 'Congo', dialCode: 242 },
  CD: { name: 'Congo, The Democratic Republic of the Congo', dialCode: 243 },
  CK: { name: 'Cook Islands', dialCode: 682 },
  CR: { name: 'Costa Rica', dialCode: 506 },
  CI: { name: 'Cote dIvoire', dialCode: 225 },
  HR: { name: 'Croatia', dialCode: 385 },
  CU: { name: 'Cuba', dialCode: 53 },
  CY: { name: 'Cyprus', dialCode: 357 },
  CZ: { name: 'Czech Republic', dialCode: 420 },
  DK: { name: 'Denmark', dialCode: 45 },
  DJ: { name: 'Djibouti', dialCode: 253 },
  DM: { name: 'Dominica', dialCode: 1767 },
  DO: { name: 'Dominican Republic', dialCode: 1849 },
  EC: { name: 'Ecuador', dialCode: 593 },
  EG: { name: 'Egypt', dialCode: 20 },
  SV: { name: 'El Salvador', dialCode: 503 },
  GQ: { name: 'Equatorial Guinea', dialCode: 240 },
  ER: { name: 'Eritrea', dialCode: 291 },
  EE: { name: 'Estonia', dialCode: 372 },
  ET: { name: 'Ethiopia', dialCode: 251 },
  FK: { name: 'Falkland Islands (Malvinas)', dialCode: 500, priority: true },
  FO: { name: 'Faroe Islands', dialCode: 298 },
  FJ: { name: 'Fiji', dialCode: 679 },
  FI: { name: 'Finland', dialCode: 358, priority: true },
  FR: { name: 'France', dialCode: 33 },
  GF: { name: 'French Guiana', dialCode: 594 },
  PF: { name: 'French Polynesia', dialCode: 689 },
  TF: { name: 'French Southern Territories', dialCode: 262, priority: true },
  GA: { name: 'Gabon', dialCode: 241 },
  GM: { name: 'Gambia', dialCode: 220 },
  GE: { name: 'Georgia', dialCode: 995 },
  DE: { name: 'Germany', dialCode: 49 },
  GH: { name: 'Ghana', dialCode: 233 },
  GI: { name: 'Gibraltar', dialCode: 350 },
  GR: { name: 'Greece', dialCode: 30 },
  GL: { name: 'Greenland', dialCode: 299 },
  GD: { name: 'Grenada', dialCode: 1473 },
  GP: { name: 'Guadeloupe', dialCode: 590, priority: true },
  GU: { name: 'Guam', dialCode: 1671 },
  GT: { name: 'Guatemala', dialCode: 502 },
  GG: { name: 'Guernsey', dialCode: 44 },
  GN: { name: 'Guinea', dialCode: 224 },
  GW: { name: 'Guinea-Bissau', dialCode: 245 },
  GY: { name: 'Guyana', dialCode: 592 },
  HT: { name: 'Haiti', dialCode: 509 },
  HM: { name: 'Heard Island and Mcdonald Islands', dialCode: 0 },
  VA: { name: 'Holy See (Vatican City State)', dialCode: 379 },
  HN: { name: 'Honduras', dialCode: 504 },
  HK: { name: 'Hong Kong', dialCode: 852 },
  HU: { name: 'Hungary', dialCode: 36 },
  IS: { name: 'Iceland', dialCode: 354 },
  IN: { name: 'India', dialCode: 91 },
  ID: { name: 'Indonesia', dialCode: 62 },
  IR: { name: 'Iran, Islamic Republic of Persian Gulf', dialCode: 98 },
  IQ: { name: 'Iraq', dialCode: 964 },
  IE: { name: 'Ireland', dialCode: 353 },
  IM: { name: 'Isle of Man', dialCode: 44 },
  IL: { name: 'Israel', dialCode: 972 },
  IT: { name: 'Italy', dialCode: 39 },
  JM: { name: 'Jamaica', dialCode: 1876 },
  JP: { name: 'Japan', dialCode: 81 },
  JE: { name: 'Jersey', dialCode: 44 },
  JO: { name: 'Jordan', dialCode: 962 },
  KZ: { name: 'Kazakhstan', dialCode: 7 },
  KE: { name: 'Kenya', dialCode: 254 },
  KI: { name: 'Kiribati', dialCode: 686 },
  KP: { name: 'Korea, Democratic People', dialCode: 850 },
  KR: { name: 'Korea, Republic of South Korea', dialCode: 82 },
  XK: { name: 'Kosovo', dialCode: 383 },
  KW: { name: 'Kuwait', dialCode: 965 },
  KG: { name: 'Kyrgyzstan', dialCode: 996 },
  LA: { name: 'Laos', dialCode: 856 },
  LV: { name: 'Latvia', dialCode: 371 },
  LB: { name: 'Lebanon', dialCode: 961 },
  LS: { name: 'Lesotho', dialCode: 266 },
  LR: { name: 'Liberia', dialCode: 231 },
  LY: { name: 'Libyan Arab Jamahiriya', dialCode: 218 },
  LI: { name: 'Liechtenstein', dialCode: 423 },
  LT: { name: 'Lithuania', dialCode: 370 },
  LU: { name: 'Luxembourg', dialCode: 352 },
  MO: { name: 'Macao', dialCode: 853 },
  MK: { name: 'Macedonia', dialCode: 389 },
  MG: { name: 'Madagascar', dialCode: 261 },
  MW: { name: 'Malawi', dialCode: 265 },
  MY: { name: 'Malaysia', dialCode: 60 },
  MV: { name: 'Maldives', dialCode: 960 },
  ML: { name: 'Mali', dialCode: 223 },
  MT: { name: 'Malta', dialCode: 356 },
  MH: { name: 'Marshall Islands', dialCode: 692 },
  MQ: { name: 'Martinique', dialCode: 596 },
  MR: { name: 'Mauritania', dialCode: 222 },
  MU: { name: 'Mauritius', dialCode: 230 },
  YT: { name: 'Mayotte', dialCode: 262 },
  MX: { name: 'Mexico', dialCode: 52 },
  FM: { name: 'Micronesia, Federated States of Micronesia', dialCode: 691 },
  MD: { name: 'Moldova', dialCode: 373 },
  MC: { name: 'Monaco', dialCode: 377 },
  MN: { name: 'Mongolia', dialCode: 976 },
  ME: { name: 'Montenegro', dialCode: 382 },
  MS: { name: 'Montserrat', dialCode: 1664 },
  MA: { name: 'Morocco', dialCode: 212 },
  MZ: { name: 'Mozambique', dialCode: 258 },
  MM: { name: 'Myanmar', dialCode: 95 },
  NA: { name: 'Namibia', dialCode: 264 },
  NR: { name: 'Nauru', dialCode: 674 },
  NP: { name: 'Nepal', dialCode: 977 },
  NL: { name: 'Netherlands', dialCode: 31 },
  NC: { name: 'New Caledonia', dialCode: 687 },
  NZ: { name: 'New Zealand', dialCode: 64, priority: true },
  NI: { name: 'Nicaragua', dialCode: 505 },
  NE: { name: 'Niger', dialCode: 227 },
  NG: { name: 'Nigeria', dialCode: 234 },
  NU: { name: 'Niue', dialCode: 683 },
  NF: { name: 'Norfolk Island', dialCode: 672 },
  MP: { name: 'Northern Mariana Islands', dialCode: 1670 },
  NO: { name: 'Norway', dialCode: 47, priority: true },
  OM: { name: 'Oman', dialCode: 968 },
  PK: { name: 'Pakistan', dialCode: 92 },
  PW: { name: 'Palau', dialCode: 680 },
  PS: { name: 'Palestinian Territory', dialCode: 970 },
  PA: { name: 'Panama', dialCode: 507 },
  PG: { name: 'Papua New Guinea', dialCode: 675 },
  PY: { name: 'Paraguay', dialCode: 595 },
  PE: { name: 'Peru', dialCode: 51 },
  PH: { name: 'Philippines', dialCode: 63 },
  PN: { name: 'Pitcairn', dialCode: 64 },
  PL: { name: 'Poland', dialCode: 48 },
  PT: { name: 'Portugal', dialCode: 351 },
  PR: { name: 'Puerto Rico', dialCode: 1939 },
  QA: { name: 'Qatar', dialCode: 974 },
  RO: { name: 'Romania', dialCode: 40 },
  RU: { name: 'Russia', dialCode: 7, priority: true },
  RW: { name: 'Rwanda', dialCode: 250 },
  RE: { name: 'Reunion', dialCode: 262 },
  BL: { name: 'Saint Barthelemy', dialCode: 590 },
  SH: { name: 'Saint Helena, Ascension and Tristan Da Cunha', dialCode: 290 },
  KN: { name: 'Saint Kitts and Nevis', dialCode: 1869 },
  LC: { name: 'Saint Lucia', dialCode: 1758 },
  MF: { name: 'Saint Martin', dialCode: 590 },
  PM: { name: 'Saint Pierre and Miquelon', dialCode: 508 },
  VC: { name: 'Saint Vincent and the Grenadines', dialCode: 1784 },
  WS: { name: 'Samoa', dialCode: 685 },
  SM: { name: 'San Marino', dialCode: 378 },
  ST: { name: 'Sao Tome and Principe', dialCode: 239 },
  SA: { name: 'Saudi Arabia', dialCode: 966 },
  SN: { name: 'Senegal', dialCode: 221 },
  RS: { name: 'Serbia', dialCode: 381 },
  SC: { name: 'Seychelles', dialCode: 248 },
  SL: { name: 'Sierra Leone', dialCode: 232 },
  SG: { name: 'Singapore', dialCode: 65 },
  SX: { name: 'Sint Maarten', dialCode: 721 },
  SK: { name: 'Slovakia', dialCode: 421 },
  SI: { name: 'Slovenia', dialCode: 386 },
  SB: { name: 'Solomon Islands', dialCode: 677 },
  SO: { name: 'Somalia', dialCode: 252 },
  ZA: { name: 'South Africa', dialCode: 27 },
  SS: { name: 'South Sudan', dialCode: 211 },
  GS: { name: 'South Georgia and the South Sandwich Islands', dialCode: 500 },
  ES: { name: 'Spain', dialCode: 34 },
  LK: { name: 'Sri Lanka', dialCode: 94 },
  SD: { name: 'Sudan', dialCode: 249 },
  SR: { name: 'Suriname', dialCode: 597 },
  SJ: { name: 'Svalbard and Jan Mayen', dialCode: 47 },
  SZ: { name: 'Swaziland', dialCode: 268 },
  SE: { name: 'Sweden', dialCode: 46 },
  CH: { name: 'Switzerland', dialCode: 41 },
  SY: { name: 'Syrian Arab Republic', dialCode: 963 },
  TW: { name: 'Taiwan', dialCode: 886 },
  TJ: { name: 'Tajikistan', dialCode: 992 },
  TZ: { name: 'Tanzania, United Republic of Tanzania', dialCode: 255 },
  TH: { name: 'Thailand', dialCode: 66 },
  TL: { name: 'Timor-Leste', dialCode: 670 },
  TG: { name: 'Togo', dialCode: 228 },
  TK: { name: 'Tokelau', dialCode: 690 },
  TO: { name: 'Tonga', dialCode: 676 },
  TT: { name: 'Trinidad and Tobago', dialCode: 1868 },
  TN: { name: 'Tunisia', dialCode: 216 },
  TR: { name: 'Turkey', dialCode: 90 },
  TM: { name: 'Turkmenistan', dialCode: 993 },
  TC: { name: 'Turks and Caicos Islands', dialCode: 1649 },
  TV: { name: 'Tuvalu', dialCode: 688 },
  UG: { name: 'Uganda', dialCode: 256 },
  UA: { name: 'Ukraine', dialCode: 380 },
  AE: { name: 'United Arab Emirates', dialCode: 971 },
  GB: { name: 'United Kingdom', dialCode: 44, priority: true },
  US: { name: 'United States', dialCode: 1, priority: true },
  UY: { name: 'Uruguay', dialCode: 598 },
  UZ: { name: 'Uzbekistan', dialCode: 998 },
  VU: { name: 'Vanuatu', dialCode: 678 },
  VE: { name: 'Venezuela, Bolivarian Republic of Venezuela', dialCode: 58 },
  VN: { name: 'Vietnam', dialCode: 84 },
  VG: { name: 'Virgin Islands, British', dialCode: 1284 },
  VI: { name: 'Virgin Islands, US', dialCode: 1340 },
  WF: { name: 'Wallis and Futuna', dialCode: 681 },
  YE: { name: 'Yemen', dialCode: 967 },
  ZM: { name: 'Zambia', dialCode: 260 },
  ZW: { name: 'Zimbabwe', dialCode: 263 },
};

export const DIAL_CODES: string[] = Object.values(COUNTRIES).map((country) => country.dialCode.toString());

export const SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST: string[] = ['RU', 'BY', 'CU', 'IR', 'KP', 'SY', 'LB', 'YE'];

export const SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST = [
  COUNTRY_CODES.France,
  COUNTRY_CODES.Italy,
  COUNTRY_CODES.Netherlands,
];

export default COUNTRIES;
