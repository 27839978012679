import { SupplierRegistrationProcess, SupplierRegistrationProcessServerResponse } from '@mortee/domain/morteeRegistrationForms';
import { transformToSupplierRegistrationProcess } from '@mortee/services/supplierRegistrationManagementServices';
import { trim, trimToNull } from '@app/utils/stringUtils';
import * as messageLauncher from '@app/utils/messageLauncher';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';
import { SanctionsScreeningResultStatus } from '@app/domain/validatedPayee';
import { ValidatedPayeeAccountValidationStatusSupplierValidationVerification } from '@app/domain/validatedPayeeAccount';
import {
  DEFAULT_EQUALS_FUNCTION,
  isDefined,
  LEGAL_IDENTIFIER_EQUALS_FUNCTION,
  SCREENING_EQUALS_FUNCTION,
} from '@app/utils/utils';
import {
  transformValidatedPayeeAccountStoreWarning,
  transformValidatedPayeeStoreWarning,
  ValidatedPayeeAccountStoreWarning,
  ValidatedPayeeAccountStoreWarningServerResponse,
  ValidatedPayeeAccountVisibility,
  ValidatedPayeeStoreWarning,
  ValidatedPayeeStoreWarningServerResponse,
} from '@mortee/domain/vaildatedPayeeManagement';
import { setsEqual } from '@app/utils/setUtils';
import { convertStatusesIndicationsToStatusSubsets } from '@app/utils/statusUtils';
import {
  transformVerificationLegalEntityIdentifierToVerificationLegalIdentifierRequest,
  VerificationLegalEntityIdentifier,
  VerificationLegalEntityIdentifierServerResponse,
  VerificationLegalIdentifierRequest,
  VerificationStrongLegalIdentifies,
  VerificationStrongLegalIdentifiesServerResponse,
} from '@mortee/domain/validationSystemVerificationLegalIdentifiers';
import { ColorScheme } from '@app/domain/theme';
import { mergeArrays } from '@app/utils/arrayUtils';
import { COUNTRY_CODES } from '@app/domain/countries';
import { AlertStatus } from '@mortee/domain/validationSystemAlerts';
import { ValidatedPayeeVisibility } from '../../../infra/domain/aggregatedValidatedPayee';

export interface StoreSupplierValidationRecordRequest {
  startDate: number | null;
  organizationId: string | null;
  supplierName: string;
  initialEmailId: string | null;
  initiatorEmail: string | null;
  companyCode: string | null;
  svRegistrationId: string | null;
  storeValidationRecordCommentRequest: StoreSupplierValidationComment | null;
  autoMailerManualLink: string | null;
  manualCompletionDate: number | null;
  svLinkId: string | null;
}

export interface StoreSupplierValidationComment {
  comment: string;
  commentType: CommentTypes;
}

export interface StoreSupplierValidationAlert {
  alertText: string;
}

enum SurepayTaxIdType {
  euVat = 'EU_VAT',
  nlKvk = 'NL_KVK',
  frSiret = 'FR_SIRET',
  frSiren = 'FR_SIREN',
}

export const surepayTaxIdTypeText: Record<SurepayTaxIdType, string> = {
  [SurepayTaxIdType.euVat]: 'VAT',
  [SurepayTaxIdType.nlKvk]: 'Company ID',
  [SurepayTaxIdType.frSiret]: 'Company ID',
  [SurepayTaxIdType.frSiren]: 'Company ID',
};

export enum EvidenceType {
  bofa = 'BOFA',
  chase = 'Chase',
  barclays = 'Barclays',
  adiv = 'ADIV',
  shopsell = 'Shopsell',
  crowdKnowledge = 'CrowdKnowledge',
  finicityOpenBanking = 'FinicityOpenBanking',
  barclaysGBP = 'BarclaysGBP',
  cop = 'COP',
  mexicoCEP = 'MexicoCEP',
  bofaValidation = 'BOFAValidation',
  lyonsValidation = 'LyonsOnlineValidation',
  surepayValidation = 'SurepayOnlineValidation',
}

export const evidenceTypeText: Record<EvidenceType, string> = {
  [EvidenceType.bofa]: 'BOFA',
  [EvidenceType.chase]: 'Chase',
  [EvidenceType.barclays]: 'Barclays',
  [EvidenceType.adiv]: 'ADIV',
  [EvidenceType.shopsell]: 'Shopsell',
  [EvidenceType.crowdKnowledge]: 'Crowd Knowledge',
  [EvidenceType.finicityOpenBanking]: 'Open Banking US - Finicity',
  [EvidenceType.bofaValidation]: 'BOFA Validation',
  [EvidenceType.barclaysGBP]: 'Barclays GBP',
  [EvidenceType.cop]: 'COP',
  [EvidenceType.mexicoCEP]: 'Mexico CEP',
  [EvidenceType.lyonsValidation]: 'Lyons',
  [EvidenceType.surepayValidation]: 'Surepay',
};

export enum SupplierValidationRecordStatus {
  completed = 'Completed',
  inProgress = 'InProgress',
  inApproval = 'InApproval',
  completedWithNoEmails = 'CompletedWithNoEmails',
  canceled = 'Canceled',
  hold = 'Hold',
  deleted = 'Deleted',
  clarification = 'Clarification',
  validationDraft = 'ValidationDraft',
  updateCompleted = 'UpdateCompleted',
  waitingForApproval = 'WaitingForApproval',
}

interface SupplierValidationRecordStatusIndicators {
  relevantToLinkToRegistrationProcess: boolean;
  preWaitingApproval: boolean;
  shouldOpenEditModalOnVerificationTab: boolean;
}

export const SupplierValidationRecordStatusSubsets = convertStatusesIndicationsToStatusSubsets<
  SupplierValidationRecordStatus,
  SupplierValidationRecordStatusIndicators
>({
  [SupplierValidationRecordStatus.completed]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: false,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.inProgress]: {
    relevantToLinkToRegistrationProcess: true,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.inApproval]: {
    relevantToLinkToRegistrationProcess: true,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.completedWithNoEmails]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: false,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.canceled]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.hold]: {
    relevantToLinkToRegistrationProcess: true,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.deleted]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: false,
  },
  [SupplierValidationRecordStatus.clarification]: {
    relevantToLinkToRegistrationProcess: true,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: true,
  },
  [SupplierValidationRecordStatus.updateCompleted]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: true,
  },
  [SupplierValidationRecordStatus.waitingForApproval]: {
    relevantToLinkToRegistrationProcess: false,
    preWaitingApproval: false,
    shouldOpenEditModalOnVerificationTab: true,
  },
  [SupplierValidationRecordStatus.validationDraft]: {
    relevantToLinkToRegistrationProcess: true,
    preWaitingApproval: true,
    shouldOpenEditModalOnVerificationTab: true,
  },
});

export enum CommentTypes {
  publicComment = 'PublicComment',
  internalComment = 'InternalComment',
  internalValidation = 'InternalValidation',
}

export type CommentTypeChipData = { text: string; colorScheme: ColorScheme };

export const commentTypesDisplayData: Record<CommentTypes, CommentTypeChipData> = {
  [CommentTypes.publicComment]: {
    text: 'Public',
    colorScheme: { main: '#243BBC', fullHover: '#324bcc', fullActive: '#5165d9', fullTextColor: '#FFFFFF' },
  },
  [CommentTypes.internalComment]: {
    text: 'Internal',
    colorScheme: { main: '#2B8387', fullHover: '#26999f', fullActive: '#1cc3cb', fullTextColor: '#FFFFFF' },
  },
  [CommentTypes.internalValidation]: {
    text: 'Verification',
    colorScheme: { main: '#2E8540', fullHover: '#42a156', fullActive: '#5cb76f', fullTextColor: '#FFFFFF' },
  },
};

export const alertTypesDisplayData: Record<AlertStatus, CommentTypeChipData> = {
  [AlertStatus.active]: {
    text: 'Open',
    colorScheme: { main: '#2B8387', fullHover: '#26999f', fullActive: '#1cc3cb', fullTextColor: '#FFFFFF' },
  },
  [AlertStatus.resolved]: {
    text: 'Resolved',
    colorScheme: { main: '#2E8540', fullHover: '#42a156', fullActive: '#5cb76f', fullTextColor: '#FFFFFF' },
  },
};

export enum SVMasterFileAutomaticEmailStatus {
  notStarted = 'NotStarted',
  inProgress = 'InProgress',
  error = 'Error',
  stopped = 'Stopped',
  ended = 'Ended',
}

export enum SVMasterFileAutomaticEmailStopReason {
  originalEmailNotFound = 'OriginalEmailNotFound',
  automaticEmailNotFound = 'AutomaticEmailNotFound',
  sendingEmailFailed = 'SendingEmailFailed',
  emailTemplateProcessingFailed = 'EmailTemplateProcessingFailed',
  invalidEmailParameters = 'InvalidEmailParameters',
  invalidEmailMessageEmailId = 'InvalidEmailMessageEmailId',
  generalError = 'GeneralError',
  registered = 'Registered',
  customerInvolvement = 'CustomerInvolvement',
  newPhase = 'NewPhase',
  emailInterruption = 'EmailInterruption',
  noResponse = 'NoResponse',
  stopAutomaticEmailProcessRequest = 'StopAutomaticEmailProcessRequest',
}

export enum SupplierValidationRecordDistributorWarning {
  notSent = 'DISTRIBUTION_EMAIL_NOT_SENT_ERROR',
  notAvailable = 'DISTRIBUTOR_NOT_AVAILABLE_FOR_THIS_TRIGGER_ERROR',
  notFound = 'MATCHING_DISTRIBUTOR_NOT_FOUND_ERROR',
}

export interface AssigneeServerResponse {
  tenantUserId: string;
  tenantUserName: string;
  isAssignable: boolean;
}

export interface Assignee {
  id: string;
  name: string;
  isAssignable: boolean;
  isMe: boolean;
}

const distributionWarningMessages = {
  [SupplierValidationRecordDistributorWarning.notSent]: 'Distribution was not sent due to server error',
  [SupplierValidationRecordDistributorWarning.notAvailable]:
    'This instruction type distribution is not available on this trigger',
  [SupplierValidationRecordDistributorWarning.notFound]: 'Distribution is not yet implemented for the record instruction type',
};

export function displayDistributionWarningMessage(warnings: string[] | null): void {
  if (!warnings?.length) {
    return;
  }

  messageLauncher.shoot({ type: 'warning', duration: 10, closeable: true }, distributionWarningMessages[warnings[0]]);
}

export function transformAssignee(serverResponse: AssigneeServerResponse, myUserId: string | undefined): Assignee {
  return {
    id: serverResponse.tenantUserId,
    name: serverResponse.tenantUserName,
    isAssignable: serverResponse.isAssignable,
    isMe: myUserId ? serverResponse.tenantUserId === myUserId : false,
  };
}

export interface ValidationSystemOrganizationServerResponse {
  id: string;
  name: string;
  groupName: string | null | undefined;
  ref: string | null | undefined;
  subdomain: string | null | undefined;
  defaultCompanyCode: string | null | undefined;
}

export interface ValidationSystemOrganization {
  id: string;
  name: string;
  groupName: string | null;
  ref: string | null;
  subdomain: string | null;
  companyCode: string | null;
}

export function transformValidationSystemOrganization(
  serverResponse: ValidationSystemOrganizationServerResponse,
): ValidationSystemOrganization {
  return {
    id: serverResponse.id,
    name: serverResponse.name,
    groupName: serverResponse.groupName ?? null,
    companyCode: serverResponse.defaultCompanyCode ?? null,
    ref: serverResponse.ref ?? null,
    subdomain: serverResponse.subdomain ?? null,
  };
}

export interface CancelReasonServerResponse {
  id: string;
  reason: string;
}

export interface CancelReason {
  id: string;
  reason: string;
}

export function transformToCancelReason(serverResponse: CancelReasonServerResponse): CancelReason {
  return serverResponse;
}

export interface SupplierValidationRecordWithWarningsServerResponse {
  record: SupplierValidationRecordServerResponse;
  warnings: string[];
}

interface SupplierValidationRecordStatusObjectServerResponse {
  value: SupplierValidationRecordStatus;
  cancelReason: CancelReasonServerResponse | null | undefined;
}

export interface SupplierValidationRecordStatusObject {
  value: SupplierValidationRecordStatus;
  cancelReason: CancelReason | null;
}

export enum SupplierValidationRecordManualInstructionType {
  sepaLinkedAccount = 'SepaLinkedAccount',
  bofaLinkedAccount = 'BofaLinkedAccount',
  gbGbpLinkedAccount = 'GbGbpLinkedAccount',
  ewsCandidate = 'EwsCandidate',
  ewsMatch = 'EwsMatch',
}

export enum SupplierValidationRecordCalculatedInstructionType {
  checkAccount = 'HasRelatedValidatedPayees',
  crowdKnowledge = 'IsCrowdKnowledgeCandidate',
}

export type SupplierValidationRecordInstructionType =
  | SupplierRegistrationProcessInstructionType
  | SupplierValidationRecordManualInstructionType
  | SupplierValidationRecordCalculatedInstructionType;

export const allSupplierValidationRecordInstructionTypes = mergeArrays<SupplierValidationRecordInstructionType>([
  Object.values(SupplierValidationRecordManualInstructionType),
  Object.values(SupplierRegistrationProcessInstructionType),
]);

export const allSupplierValidationRecordInstructionTypesWithCalculated = mergeArrays<SupplierValidationRecordInstructionType>([
  Object.values(SupplierValidationRecordManualInstructionType),
  Object.values(SupplierRegistrationProcessInstructionType),
  Object.values(SupplierValidationRecordCalculatedInstructionType),
]);

export const SupplierValidationRecordInstructionTypesSubsets = convertStatusesIndicationsToStatusSubsets<
  SupplierValidationRecordInstructionType,
  {
    svmManualInstructionType: boolean;
    svmTableFilter: boolean;
  }
>({
  [SupplierRegistrationProcessInstructionType.poalimBankWireTransfer]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.leumiUSBankWireTransfer]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.achChaseWireTransfer]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.sepaWireTransfer]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bofaWireTransfer]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bofaWirePingPong]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.canadaPayoneerPingPong]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.barclaysGBP]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.barclaysEUR]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.barclaysPingPong]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.barclaysUSD]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidation]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBofa]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBlocked]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationPoalim]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationPoland]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIndia]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIndonesia]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationChina]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationGreatBritain]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationSouthAfrica]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationKorea]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIsrael]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBrazil]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationEurope]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationUSOpenBanking]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationLyons]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityPoalimBankWireTransfer]: {
    svmManualInstructionType: false,
    svmTableFilter: false,
  },
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityLeumiUSBankWireTransfer]: {
    svmManualInstructionType: false,
    svmTableFilter: false,
  },
  [SupplierRegistrationProcessInstructionType.IPSBarclaysEUR]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.IPSBOFAWireTransfer]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.japanPayoneer]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.mexicoFrictionless]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.mexicoAlternative]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.chileLF]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.argentinaLF]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.vietnamLF]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.pingPongUSD]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.pingPongLocalCurrency]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierRegistrationProcessInstructionType.ilLinkedAccount]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierValidationRecordManualInstructionType.gbGbpLinkedAccount]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierValidationRecordManualInstructionType.ewsMatch]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierValidationRecordManualInstructionType.bofaLinkedAccount]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierValidationRecordManualInstructionType.ewsCandidate]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierValidationRecordManualInstructionType.sepaLinkedAccount]: {
    svmManualInstructionType: true,
    svmTableFilter: true,
  },
  [SupplierValidationRecordCalculatedInstructionType.checkAccount]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
  [SupplierValidationRecordCalculatedInstructionType.crowdKnowledge]: {
    svmManualInstructionType: false,
    svmTableFilter: true,
  },
});

function transformToSupplierValidationRecordStatusObject(
  serverResponse: SupplierValidationRecordStatusObjectServerResponse,
): SupplierValidationRecordStatusObject {
  return {
    value: serverResponse.value,
    cancelReason: serverResponse.cancelReason ? transformToCancelReason(serverResponse.cancelReason) : null,
  };
}

export interface SupplierValidationRecordServerResponse {
  // Creation data
  staticId: string;
  versionId: string;
  presentationId: string;
  createTimestamp: number;
  createdByUserName: string;

  // Update data
  lastActivityTimestamp: number;
  lastActivityUserName: string;

  // Record Metadata
  startDate: number | null;
  status: SupplierValidationRecordStatusObjectServerResponse;
  assignee: AssigneeServerResponse | null | undefined;

  // Supplier data
  supplierName: string;
  organization: ValidationSystemOrganizationServerResponse | null | undefined;
  migrationRegistrationDate: number | null | undefined;
  supplierValidationProcess: SupplierRegistrationProcessServerResponse | null | undefined;
  companyCode: string | null | undefined;
  taxId: string | null | undefined;
  knoxId: string | null | undefined;
  manualCountryCode: string | null | undefined;

  // Users data about payments
  nsKnoxTransferTimestamp: number | null | undefined;
  payeeTransferTimestamp: number | null | undefined;

  // Users data about AutoMailer
  isCustomerInvolvementRequired: boolean;
  firstContactTimestamp: number | null | undefined;

  // AutoMailer data about AutoMailer
  initialEmailId: string | null | undefined;
  emailStatus: SVMasterFileAutomaticEmailStatus | null | undefined;
  message: SVMasterFileAutomaticEmailStopReason | null | undefined;
  emailCount: number;
  lastEmailTimestamp: number | null | undefined;
  lastEmailId: string | null | undefined;
  stopEmails: boolean;
  autoMailerManualLink: string | null;
  svLinkId: string | null | undefined;
  isOfflineForm: boolean | null | undefined;

  // Distribution data
  distributions: DistributionsServerResponse;
  isClosurePerformed: boolean;

  manualCompletionDate: number | null | undefined;
  manualInstructionType: SupplierRegistrationProcessInstructionType | null | undefined;

  initiatorEmail: string | null | undefined;
  // Verification data
  evidence: SupplierValidationVerificationRecordEvidenceServerResponse | null | undefined;
  externalValidationId: string | null | undefined;
}

export interface DistributionsServerResponse {
  distributionCount: number;
  isDistributed: boolean;
  isDistributable: boolean;
  isScheduledDistributable: boolean;
  isSingleUserRequestDistributable: boolean;
}

export interface SupplierValidationVerificationSanctionsScreeningInfoServerResponse {
  result: SanctionsScreeningResultStatus | undefined | null;
  screeningTimestamp: number | undefined | null;
}

export interface SupplierValidationVerificationSanctionsScreeningInfo {
  result: SanctionsScreeningResultStatus | undefined;
  screeningTimestamp: number | undefined;
}

function transformSupplierValidationVerificationSanctionsScreeningInfo(
  serverResponse: SupplierValidationVerificationSanctionsScreeningInfoServerResponse,
): SupplierValidationVerificationSanctionsScreeningInfo {
  return {
    result: serverResponse.result ?? undefined,
    screeningTimestamp: serverResponse.screeningTimestamp ?? undefined,
  };
}

export interface SupplierValidationVerificationSanctionsScreeningInfoRequest {
  result: SanctionsScreeningResultStatus | undefined;
  screeningTimestamp: number | undefined;
}

export interface SupplierValidationVerificationEvidenceServerResponse {
  performingUserName: string;
  writeTimestamp: number;
  records: SupplierValidationVerificationRecordEvidenceServerResponse[];
}

export interface EvidenceDataServerResponse {
  payeeName: string | null | undefined;
  bankName: string | null | undefined;
  address: string | null | undefined;
  comment: string | null | undefined;
}

export interface SupplierValidationVerificationRecordEvidenceServerResponse {
  id: string;
  validationRecordPresentationId: string;
  swiftCode: string | null | undefined;
  iban: string | null | undefined;
  effectiveDate: number | null | undefined;
  type: EvidenceType;
  data: EvidenceDataServerResponse;
  amount: string | null | undefined;
  currency: string | null | undefined;
  bankCode: string | null | undefined;
  branchCode: string | null | undefined;
  accountNumber: string | null | undefined;
  taxId: string | null | undefined;
  taxIdType: string | null | undefined;
  taxIdCountryCode: string | null | undefined;
  result: string | null | undefined;
  evidenceRecordId: string | null | undefined;
  customerId: string | null | undefined;
  isDeletable: boolean | null | undefined;
  jsonRawData: string | null | undefined;
}

export interface EvidenceData {
  payeeName: string | null;
  bankName: string | null;
  address: string | null;
  comment: string | null;
}

interface AlreadyExistingValidatedPayeeIdsServerResponse {
  uniformId: string;
  knoxId: string;
  displayName: string;
  visibility: ValidatedPayeeVisibility;
}

interface AlreadyExistingValidatedPayeeIds {
  uniformId: string;
  knoxId: string;
  displayName: string;
  visibility: ValidatedPayeeVisibility;
}

export interface SupplierValidationVerificationRecordEvidence {
  id: string;
  validationRecordPresentationId: string;
  swiftCode: string | null;
  iban: string | null;
  effectiveDate: number | null;
  type: EvidenceType | null;
  data: EvidenceData;
  amount: string | null;
  currency: string | null;
  bankCode: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  taxId: string | null;
  taxIdType: string | null;
  taxIdCountryCode: string | null;
  result: string | null;
  evidenceRecordId: string | null;
  customerId: string | null;
  isDeletable: boolean | null;
  jsonRawData: string | null;
}

export function transformSupplierValidationVerificationRecordEvidence(
  serverResponse: SupplierValidationVerificationRecordEvidenceServerResponse,
): SupplierValidationVerificationRecordEvidence {
  return {
    id: serverResponse.id,
    validationRecordPresentationId: serverResponse.validationRecordPresentationId,
    iban: serverResponse.iban ?? null,
    swiftCode: serverResponse.swiftCode ?? null,
    effectiveDate: serverResponse.effectiveDate ?? null,
    type: serverResponse.type,
    data: {
      bankName: serverResponse.data.bankName ?? null,
      payeeName: serverResponse.data.payeeName ?? null,
      address: serverResponse.data.address ?? null,
      comment: serverResponse.data.comment ?? null,
    },
    amount: serverResponse.amount ?? null,
    currency: serverResponse.currency ?? null,
    accountNumber: serverResponse.accountNumber ?? null,
    bankCode: serverResponse.bankCode ?? null,
    branchCode: serverResponse.branchCode ?? null,
    evidenceRecordId: serverResponse.evidenceRecordId ?? null,
    result: serverResponse.result ?? null,
    taxId: serverResponse.taxId ?? null,
    taxIdType: serverResponse.taxIdType ?? null,
    taxIdCountryCode: serverResponse.taxIdCountryCode ?? null,
    customerId: serverResponse.customerId ?? null,
    isDeletable: serverResponse.isDeletable ?? null,
    jsonRawData: serverResponse.jsonRawData ?? null,
  };
}

export interface SupplierValidationPayeeVerificationRecordServerResponse {
  validationRecordStaticId: string;
  name: string | null | undefined;
  additionalName: string | null | undefined;
  address: string | null | undefined;
  additionalAddress: string | null | undefined;
  countryCode: string | null | undefined;
  legalEntityIdentifiers: VerificationLegalEntityIdentifierServerResponse[];
  strongLegalIdentifies: VerificationStrongLegalIdentifiesServerResponse | null | undefined;
  sanctionsScreening: SupplierValidationVerificationSanctionsScreeningInfoServerResponse | null | undefined;
  alreadyExistingValidatedPayee: AlreadyExistingValidatedPayeeIdsServerResponse | null | undefined;
  userId: string;
  createdValidatedPayeeId: string | null | undefined;
  effectiveTimestamp: number | null | undefined;
  isPrivate: boolean;
  isUpdateRequired: boolean;
}

export interface SupplierValidationAccountVerificationRecordServerResponse {
  staticId: string;
  validationRecordStaticId: string;
  countryCode: string | null | undefined;
  swift: string | null | undefined;
  bankCode: string | null | undefined;
  bankName: string | null | undefined;
  branchCode: string | null | undefined;
  accountNumber: string | null | undefined;
  iban: string | null | undefined;
  furtherCredit: string | null | undefined;
  validationTimestamp: number | null | undefined;
  type: ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null | undefined;
  achDidReceiveAccount: boolean | null;
  achDidReceiveEin: boolean | null;
  userId: string;
  writeTimestamp: number;
  createdValidatedAccountId: string | null | undefined;
  localFormat: string | null | undefined;
  isPrivate: boolean;
  visibility: ValidatedPayeeAccountVisibility;
}

interface SupplierValidationPayeeVerificationRecordWarningsServerResponse {
  payeeVerification: SupplierValidationPayeeVerificationRecordServerResponse | null | undefined;
  warnings: ValidatedPayeeStoreWarningServerResponse[];
}

interface SupplierValidationAccountVerificationRecordWarningsServerResponse {
  accountVerification: SupplierValidationAccountVerificationRecordServerResponse;
  warnings: ValidatedPayeeAccountStoreWarningServerResponse[];
}

export interface SupplierValidationPayeeWithAccountsVerificationWarningsServerResponse {
  payee: SupplierValidationPayeeVerificationRecordWarningsServerResponse;
  accounts: SupplierValidationAccountVerificationRecordWarningsServerResponse[];
}

interface SupplierValidationPayeeVerificationRecordWarnings {
  payeeVerification: SupplierValidationPayeeVerificationRecord | null;
  warnings: ValidatedPayeeStoreWarning[];
}

export interface SupplierValidationAccountVerificationRecordWarnings {
  accountVerification: SupplierValidationAccountVerificationRecord;
  warnings: ValidatedPayeeAccountStoreWarning[];
}

export interface SupplierValidationPayeeWithAccountsVerificationWarnings {
  payee: SupplierValidationPayeeVerificationRecordWarnings;
  accounts: SupplierValidationAccountVerificationRecordWarnings[];
}

function transformSupplierValidationPayeeVerificationRecordWarnings(
  serverResponse: SupplierValidationPayeeVerificationRecordWarningsServerResponse,
): SupplierValidationPayeeVerificationRecordWarnings {
  return {
    payeeVerification: serverResponse.payeeVerification
      ? transformSupplierValidationPayeeVerification(serverResponse.payeeVerification)
      : null,
    warnings: serverResponse.warnings.map(transformValidatedPayeeStoreWarning),
  };
}

function transformSupplierValidationAccountVerificationRecordWarnings(
  serverResponse: SupplierValidationAccountVerificationRecordWarningsServerResponse,
): SupplierValidationAccountVerificationRecordWarnings {
  return {
    accountVerification: transformSupplierValidationAccountVerification(serverResponse.accountVerification),
    warnings: serverResponse.warnings.map(transformValidatedPayeeAccountStoreWarning),
  };
}

export function transformSupplierValidationPayeeWithAccountsVerificationWarnings(
  serverResponse: SupplierValidationPayeeWithAccountsVerificationWarningsServerResponse,
): SupplierValidationPayeeWithAccountsVerificationWarnings {
  return {
    payee: transformSupplierValidationPayeeVerificationRecordWarnings(serverResponse.payee),
    accounts: serverResponse.accounts.map(transformSupplierValidationAccountVerificationRecordWarnings),
  };
}

export interface SupplierValidationPayeeWithAccountsVerificationServerResponse {
  payee: SupplierValidationPayeeVerificationRecordServerResponse | null | undefined;
  accounts: SupplierValidationAccountVerificationRecordServerResponse[];
}

export interface SupplierValidationPayeeVerificationRecord {
  validationRecordStaticId: string;
  name: string | null;
  additionalName: string | null;
  address: string | null;
  additionalAddress: string | null;
  countryCode: string | null;
  legalEntityIdentifiers: VerificationLegalEntityIdentifier[] | null;
  strongLegalIdentifies: VerificationStrongLegalIdentifies | null;
  sanctionsScreening: SupplierValidationVerificationSanctionsScreeningInfo | null;
  alreadyExistingValidatedPayee: AlreadyExistingValidatedPayeeIds | null;
  userId: string;
  createdValidatedPayeeId: string | null;
  effectiveTimestamp: number | null;
  isPrivate: boolean;
  isUpdateRequired: boolean;
}

export interface SupplierValidationAccountVerificationRecord {
  staticId: string;
  validationRecordStaticId: string;
  countryCode: string | null;
  swift: string | null;
  bankCode: string | null;
  bankName: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  iban: string | null;
  furtherCredit: string | null;
  validationTimestamp: number | null;
  type: ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null;
  achDidReceiveAccount: boolean | null;
  achDidReceiveEin: boolean | null;
  userId: string;
  writeTimestamp: number;
  createdValidatedAccountId: string | null;
  localFormat: string | null;
  isPrivate: boolean;
}

export interface SupplierValidationPayeeWithAccountsVerification {
  payee: SupplierValidationPayeeVerificationRecord | null;
  accounts: SupplierValidationAccountVerificationRecord[];
}

export interface StoreSupplierValidationPayeeVerificationRecordRequest {
  name: string | null;
  additionalName: string | null;
  address: string | null;
  additionalAddress: string | null;
  countryCode: string | null;
  legalEntityIdentifiers: VerificationLegalIdentifierRequest[] | null;
  sanctionsScreening: SupplierValidationVerificationSanctionsScreeningInfoRequest | null;
  alreadyExistingValidatedPayeeKnoxId: string | null;
  isPrivate: boolean;
  isUpdateRequired: boolean;
  effectiveTimestamp: number | null;
}

export interface StoreSupplierValidationAccountVerificationRecordRequest {
  countryCode: string | null;
  swift: string | null;
  bankCode: string | null;
  bankName: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  iban: string | null;
  furtherCredit: string | null;
  validationTimestamp: number | null;
  type: ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null;
  achDidReceiveAccount: boolean | null;
  achDidReceiveEin: boolean | null;
  localFormat: string | null;
  isPrivate: boolean;
}

export type SupplierValidationVerificationRecordPayeeUpdateRequest = UpdateRequestObject<StoreSupplierValidationPayeeVerificationRecordRequest>;

export type SupplierValidationVerificationRecordAccountUpdateRequest = UpdateRequestObject<StoreSupplierValidationAccountVerificationRecordRequest>;

export function createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord(
  record: SupplierValidationPayeeVerificationRecord,
): StoreSupplierValidationPayeeVerificationRecordRequest {
  return {
    name: record.name,
    additionalName: record.additionalName,
    address: record.address,
    additionalAddress: record.additionalAddress,
    countryCode: record.countryCode,
    legalEntityIdentifiers:
      record.legalEntityIdentifiers?.map(transformVerificationLegalEntityIdentifierToVerificationLegalIdentifierRequest) ?? null,
    sanctionsScreening: record.sanctionsScreening
      ? transformSupplierValidationVerificationSanctionsScreeningInfo(record.sanctionsScreening)
      : null,
    alreadyExistingValidatedPayeeKnoxId: record.alreadyExistingValidatedPayee?.knoxId ?? null,
    isPrivate: record.isPrivate,
    isUpdateRequired: record.isUpdateRequired,
    effectiveTimestamp: record.effectiveTimestamp,
  };
}

export const supplierValidationRecordSupplierVerificationAllUpdateFieldsEquators: ObjectFieldsEquators<StoreSupplierValidationPayeeVerificationRecordRequest> = {
  name: DEFAULT_EQUALS_FUNCTION,
  additionalName: DEFAULT_EQUALS_FUNCTION,
  address: DEFAULT_EQUALS_FUNCTION,
  additionalAddress: DEFAULT_EQUALS_FUNCTION,
  countryCode: DEFAULT_EQUALS_FUNCTION,
  sanctionsScreening: SCREENING_EQUALS_FUNCTION,
  legalEntityIdentifiers: (first, second) => setsEqual(first, second, LEGAL_IDENTIFIER_EQUALS_FUNCTION),
  alreadyExistingValidatedPayeeKnoxId: DEFAULT_EQUALS_FUNCTION,
  isPrivate: DEFAULT_EQUALS_FUNCTION,
  isUpdateRequired: DEFAULT_EQUALS_FUNCTION,
  effectiveTimestamp: DEFAULT_EQUALS_FUNCTION,
};

export function createSupplierValidationRecordAccountVerificationAllUpdateFieldsFromRecord(
  record: SupplierValidationAccountVerificationRecord,
): StoreSupplierValidationAccountVerificationRecordRequest {
  return {
    bankCode: record.bankCode,
    branchCode: record.branchCode,
    accountNumber: record.accountNumber,
    iban: record.iban,
    swift: record.swift,
    bankName: record.bankName,
    countryCode: record.countryCode,
    furtherCredit: record.furtherCredit,
    type: record.type,
    validationTimestamp: record.validationTimestamp,
    achDidReceiveAccount: record.achDidReceiveAccount,
    achDidReceiveEin: record.achDidReceiveEin,
    localFormat: record.localFormat,
    isPrivate: record.isPrivate,
  };
}

export const supplierValidationRecordAccountVerificationAllUpdateFieldsEquators: ObjectFieldsEquators<StoreSupplierValidationAccountVerificationRecordRequest> = {
  bankCode: DEFAULT_EQUALS_FUNCTION,
  branchCode: DEFAULT_EQUALS_FUNCTION,
  accountNumber: DEFAULT_EQUALS_FUNCTION,
  iban: DEFAULT_EQUALS_FUNCTION,
  swift: DEFAULT_EQUALS_FUNCTION,
  bankName: DEFAULT_EQUALS_FUNCTION,
  countryCode: DEFAULT_EQUALS_FUNCTION,
  furtherCredit: DEFAULT_EQUALS_FUNCTION,
  type: DEFAULT_EQUALS_FUNCTION,
  validationTimestamp: DEFAULT_EQUALS_FUNCTION,
  achDidReceiveAccount: DEFAULT_EQUALS_FUNCTION,
  achDidReceiveEin: DEFAULT_EQUALS_FUNCTION,
  localFormat: DEFAULT_EQUALS_FUNCTION,
  isPrivate: DEFAULT_EQUALS_FUNCTION,
};

export interface SupplierValidationRecord {
  // Creation data
  staticId: string;
  versionId: string;
  presentationId: string;
  createTimestamp: number;
  createdByUserName: string;

  // Update data
  lastActivityTimestamp: number;
  lastActivityUserName: string;

  // Record Metadata
  startDate: number | null;
  status: SupplierValidationRecordStatusObject;
  assignee: Assignee | null;

  // Supplier data
  supplierName: string;
  organization: ValidationSystemOrganization | null;
  registrationDate: number | null;
  supplierRegistrationProcess: SupplierRegistrationProcess | null;
  companyCode: string | null;
  taxId: string | null;
  knoxId: string | null;
  manualCountryCode: string | null;

  // Users data about payments
  nsKnoxTransferTimestamp: number | null;
  payeeTransferTimestamp: number | null;

  // Users data about AutoMailer
  isCustomerInvolvementRequired: boolean;
  firstContactTimestamp: number | null;

  // AutoMailer data about AutoMailer
  initialEmailId: string | null;
  emailStatus: SVMasterFileAutomaticEmailStatus | null;
  emailStopReason: SVMasterFileAutomaticEmailStopReason | null;
  emailCount: number;
  lastEmailTimestamp: number | null;
  lastEmailId: string | null;
  stopEmails: boolean;
  autoMailerManualLink: string | null;
  svLinkId: string | null;
  isOfflineForm: boolean;

  // Distribution data
  distributions: Distributions;
  isClosurePerformed: boolean;

  manualCompletionDate: number | null;
  manualInstructionType: SupplierRegistrationProcessInstructionType | null;

  initiatorEmail: string | null;
  // Verification data
  evidence: SupplierValidationVerificationRecordEvidence | null;
  externalValidationId: string | null;
}

export interface Distributions {
  distributionCount: number;
  isDistributed: boolean;
  isDistributable: boolean;
  isScheduledDistributable: boolean;
  isSingleUserRequestDistributable: boolean;
}

export function transformSupplierValidationPayeeVerification(
  serverResponse: SupplierValidationPayeeVerificationRecordServerResponse,
): SupplierValidationPayeeVerificationRecord {
  return {
    validationRecordStaticId: serverResponse.validationRecordStaticId,
    name: serverResponse.name ?? null,
    additionalName: serverResponse.additionalName ?? null,
    address: serverResponse.address ?? null,
    additionalAddress: serverResponse.additionalAddress ?? null,
    countryCode: serverResponse.countryCode ?? null,
    legalEntityIdentifiers: serverResponse.legalEntityIdentifiers.length ? serverResponse.legalEntityIdentifiers : null,
    strongLegalIdentifies: serverResponse.strongLegalIdentifies ?? null,
    sanctionsScreening: serverResponse.sanctionsScreening
      ? transformSupplierValidationVerificationSanctionsScreeningInfo(serverResponse.sanctionsScreening)
      : null,
    alreadyExistingValidatedPayee: serverResponse.alreadyExistingValidatedPayee ?? null,
    userId: serverResponse.userId,
    createdValidatedPayeeId: serverResponse.createdValidatedPayeeId ?? null,
    effectiveTimestamp: serverResponse.effectiveTimestamp ?? null,
    isPrivate: serverResponse.isPrivate,
    isUpdateRequired: serverResponse.isUpdateRequired,
  };
}

export function transformSupplierValidationAccountVerification(
  account: SupplierValidationAccountVerificationRecordServerResponse,
): SupplierValidationAccountVerificationRecord {
  return {
    staticId: account.staticId,
    validationRecordStaticId: account.validationRecordStaticId,
    countryCode: account.countryCode ?? null,
    swift: account.swift ?? null,
    bankCode: account.bankCode ?? null,
    bankName: account.bankName ?? null,
    branchCode: account.branchCode ?? null,
    accountNumber: account.accountNumber ?? null,
    iban: account.iban ?? null,
    furtherCredit: account.furtherCredit ?? null,
    validationTimestamp: account.validationTimestamp ?? null,
    type: account.type ?? null,
    achDidReceiveAccount: account.achDidReceiveAccount,
    achDidReceiveEin: account.achDidReceiveEin,
    userId: account.userId,
    writeTimestamp: account.writeTimestamp,
    createdValidatedAccountId: account.createdValidatedAccountId ?? null,
    localFormat: account.localFormat ?? null,
    isPrivate: account.isPrivate,
  };
}

export function transformSupplierValidationPayeeWithAccountsVerification(
  serverResponse: SupplierValidationPayeeWithAccountsVerificationServerResponse,
): SupplierValidationPayeeWithAccountsVerification {
  return {
    payee: serverResponse.payee ? transformSupplierValidationPayeeVerification(serverResponse.payee) : null,
    accounts: serverResponse.accounts.map(transformSupplierValidationAccountVerification),
  };
}

function transformToDistributions(serverResponse: DistributionsServerResponse): Distributions {
  return {
    distributionCount: serverResponse.distributionCount,
    isDistributed: serverResponse.isDistributed,
    isDistributable: serverResponse.isDistributable,
    isScheduledDistributable: serverResponse.isScheduledDistributable,
    isSingleUserRequestDistributable: serverResponse.isSingleUserRequestDistributable,
  };
}

export function transformSupplierValidationRecord(
  serverResponse: SupplierValidationRecordServerResponse,
  myUserId: string | undefined,
): SupplierValidationRecord {
  const supplierRegistrationProcess = serverResponse.supplierValidationProcess
    ? transformToSupplierRegistrationProcess(serverResponse.supplierValidationProcess)
    : null;

  return {
    ...serverResponse,
    supplierRegistrationProcess,
    status: transformToSupplierValidationRecordStatusObject(serverResponse.status),
    registrationDate: supplierRegistrationProcess?.writeTimestamp ?? serverResponse.migrationRegistrationDate ?? null,
    organization: serverResponse.organization ? transformValidationSystemOrganization(serverResponse.organization) : null,
    taxId: serverResponse.taxId ?? null,
    knoxId: serverResponse.knoxId ?? null,
    manualCountryCode: serverResponse.manualCountryCode ?? null,
    firstContactTimestamp: serverResponse.firstContactTimestamp ?? null,
    nsKnoxTransferTimestamp: serverResponse.nsKnoxTransferTimestamp ?? null,
    payeeTransferTimestamp: serverResponse.payeeTransferTimestamp ?? null,
    initialEmailId: serverResponse.initialEmailId ?? null,
    initiatorEmail: serverResponse.initiatorEmail ?? null,
    companyCode: serverResponse.companyCode ?? null,
    lastEmailTimestamp: serverResponse.lastEmailTimestamp ?? null,
    lastEmailId: serverResponse.lastEmailId ?? null,
    emailStatus: serverResponse.emailStatus ?? null,
    emailStopReason: serverResponse.message ?? null,
    assignee: serverResponse.assignee ? transformAssignee(serverResponse.assignee, myUserId) : null,
    autoMailerManualLink: serverResponse.autoMailerManualLink ?? null,
    distributions: transformToDistributions(serverResponse.distributions),
    manualCompletionDate: serverResponse.manualCompletionDate ?? null,
    svLinkId: serverResponse.svLinkId ?? null,
    isOfflineForm: serverResponse.isOfflineForm ?? false,
    manualInstructionType: serverResponse.manualInstructionType ?? null,
    evidence: serverResponse.evidence ? transformSupplierValidationVerificationRecordEvidence(serverResponse.evidence) : null,
    externalValidationId: serverResponse.externalValidationId ?? null,
  };
}

export function transformSupplierValidationRecordFilterResult(
  serverResponse: PaginatedData<SupplierValidationRecordServerResponse>,
  myUserId: string | undefined,
): PaginatedData<SupplierValidationRecord> {
  return {
    ...serverResponse,
    content: serverResponse.content.map(
      (recordServerResponse): SupplierValidationRecord => transformSupplierValidationRecord(recordServerResponse, myUserId),
    ),
  };
}

export interface SupplierValidationVerificationsExportServerResponse {
  content: string;
}

export interface SupplierValidationExportServerResponse {
  content: string;
}

export interface SupplierValidationExport {
  content: string;
}

export function transformSupplierValidationExport(
  serverResponse: SupplierValidationExportServerResponse,
): SupplierValidationExport {
  return serverResponse;
}

export function transformToSupplierValidationRecordStatusUpdateRequest(
  status: SupplierValidationRecordStatusObject,
): SupplierValidationRecordStatusUpdateRequest {
  return {
    value: status.value,
    cancelReasonId: status.cancelReason?.id ?? null,
  };
}

interface SupplierValidationRecordStatusUpdateRequest {
  value: SupplierValidationRecordStatus;
  cancelReasonId: string | null;
}

export interface SupplierValidationRecordInfoUpdateFields {
  startDate: number | null;
  organizationId: string | null;
  supplierName: string;
  companyCode: string | null;
  isCustomerInvolvementRequired: boolean;
  firstContactTimestamp: number | null;
  nsKnoxTransferTimestamp: number | null;
  payeeTransferTimestamp: number | null;
  taxId: string | null;
  knoxId: string | null;
  manualCountryCode: string | null;
  manualCompletionDate: number | null;
  manualInstructionType: SupplierValidationRecordInstructionType | null;
  initiatorEmail: string | null;
}

export interface SupplierValidationRecordAutomailerUpdateFields {
  initialEmailId: string | null;
  stopEmails: boolean;
  autoMailerManualLink: string | null;
  svLinkId: string | null;
}

export interface SupplierValidationRecordOtherUpdateFields {
  assigneeId: string | null;
  status: SupplierValidationRecordStatusUpdateRequest;
  svRegistrationId: string | null;
  isOfflineForm: boolean | null;
}

export type SupplierValidationRecordAllUpdateFields = SupplierValidationRecordInfoUpdateFields &
  SupplierValidationRecordAutomailerUpdateFields &
  SupplierValidationRecordOtherUpdateFields;

export type SupplierValidationRecordUpdateRequest = UpdateRequestObject<SupplierValidationRecordAllUpdateFields>;

export interface ItemsNavigationArrowsClickHandlers {
  onPreviousClick?: () => void;
  onNextRecordClick?: () => void;
}

export function createSupplierValidationRecordInfoAllUpdateFieldsFromRecord(
  record: SupplierValidationRecord,
): SupplierValidationRecordInfoUpdateFields {
  return {
    startDate: record.startDate,
    organizationId: record.organization?.id ?? NULL_ORG.id,
    supplierName: trim(record.supplierName),
    companyCode: trimToNull(record.companyCode),
    isCustomerInvolvementRequired: record.isCustomerInvolvementRequired,
    firstContactTimestamp: record.firstContactTimestamp,
    nsKnoxTransferTimestamp: record.nsKnoxTransferTimestamp,
    payeeTransferTimestamp: record.payeeTransferTimestamp,
    taxId: trimToNull(record.taxId),
    knoxId: trimToNull(record.knoxId),
    manualCountryCode: record.manualCountryCode ?? null,
    manualCompletionDate: record.manualCompletionDate ?? null,
    manualInstructionType: record.manualInstructionType ?? null,
    initiatorEmail: trimToNull(record.initiatorEmail),
  };
}

export function createSupplierValidationRecordAutomailerUpdateFieldsFromRecord(
  record: SupplierValidationRecord,
): SupplierValidationRecordAutomailerUpdateFields {
  return {
    initialEmailId: trimToNull(record.initialEmailId),
    stopEmails: record.stopEmails,
    autoMailerManualLink: trimToNull(record.autoMailerManualLink),
    svLinkId: trimToNull(record.svLinkId),
  };
}

export function createSupplierValidationRecordAllUpdateFieldsFromRecord(
  record: SupplierValidationRecord,
): SupplierValidationRecordAllUpdateFields {
  return {
    startDate: record.startDate,
    organizationId: record.organization?.id ?? NULL_ORG.id,
    supplierName: trim(record.supplierName),
    svRegistrationId: record.supplierRegistrationProcess?.id ?? null,
    assigneeId: record.assignee?.id ?? null,
    companyCode: trimToNull(record.companyCode),
    status: transformToSupplierValidationRecordStatusUpdateRequest(record.status),
    isCustomerInvolvementRequired: record.isCustomerInvolvementRequired,
    firstContactTimestamp: record.firstContactTimestamp,
    nsKnoxTransferTimestamp: record.nsKnoxTransferTimestamp,
    payeeTransferTimestamp: record.payeeTransferTimestamp,
    initialEmailId: trimToNull(record.initialEmailId),
    initiatorEmail: trimToNull(record.initiatorEmail),
    taxId: trimToNull(record.taxId),
    knoxId: trimToNull(record.knoxId),
    manualCountryCode: record.manualCountryCode ?? null,
    stopEmails: record.stopEmails,
    autoMailerManualLink: trimToNull(record.autoMailerManualLink),
    svLinkId: trimToNull(record.svLinkId),
    manualCompletionDate: record.manualCompletionDate ?? null,
    manualInstructionType: record.manualInstructionType ?? null,
    isOfflineForm: record.isOfflineForm ?? null,
  };
}

export const supplierValidationRecordAllUpdateFieldsEquators: ObjectFieldsEquators<SupplierValidationRecordAllUpdateFields> = {
  startDate: DEFAULT_EQUALS_FUNCTION,
  organizationId: DEFAULT_EQUALS_FUNCTION,
  supplierName: DEFAULT_EQUALS_FUNCTION,
  svRegistrationId: DEFAULT_EQUALS_FUNCTION,
  assigneeId: DEFAULT_EQUALS_FUNCTION,
  companyCode: DEFAULT_EQUALS_FUNCTION,
  status: (status1, status2) => status1.value === status2.value && status1.cancelReasonId === status2.cancelReasonId,
  isCustomerInvolvementRequired: DEFAULT_EQUALS_FUNCTION,
  firstContactTimestamp: DEFAULT_EQUALS_FUNCTION,
  nsKnoxTransferTimestamp: DEFAULT_EQUALS_FUNCTION,
  payeeTransferTimestamp: DEFAULT_EQUALS_FUNCTION,
  initialEmailId: DEFAULT_EQUALS_FUNCTION,
  initiatorEmail: DEFAULT_EQUALS_FUNCTION,
  taxId: DEFAULT_EQUALS_FUNCTION,
  knoxId: DEFAULT_EQUALS_FUNCTION,
  manualCountryCode: DEFAULT_EQUALS_FUNCTION,
  stopEmails: DEFAULT_EQUALS_FUNCTION,
  autoMailerManualLink: DEFAULT_EQUALS_FUNCTION,
  svLinkId: DEFAULT_EQUALS_FUNCTION,
  manualCompletionDate: DEFAULT_EQUALS_FUNCTION,
  manualInstructionType: DEFAULT_EQUALS_FUNCTION,
  isOfflineForm: DEFAULT_EQUALS_FUNCTION,
};

export const statusStrings: Record<SupplierValidationRecordStatus, String> = {
  [SupplierValidationRecordStatus.canceled]: 'Canceled',
  [SupplierValidationRecordStatus.hold]: 'On hold',
  [SupplierValidationRecordStatus.inProgress]: 'In progress',
  [SupplierValidationRecordStatus.inApproval]: 'In approval',
  [SupplierValidationRecordStatus.completedWithNoEmails]: 'Completed with no emails',
  [SupplierValidationRecordStatus.completed]: 'Completed',
  [SupplierValidationRecordStatus.deleted]: 'Deleted',
  [SupplierValidationRecordStatus.clarification]: 'Clarification',
  [SupplierValidationRecordStatus.validationDraft]: 'Validation draft',
  [SupplierValidationRecordStatus.updateCompleted]: 'Update completed',
  [SupplierValidationRecordStatus.waitingForApproval]: 'Waiting for approval',
};

export function getSVMasterFileAutomaticEmailStatusText(status: SVMasterFileAutomaticEmailStatus): string {
  switch (status) {
    case SVMasterFileAutomaticEmailStatus.notStarted:
      return 'Not Started';
    case SVMasterFileAutomaticEmailStatus.inProgress:
      return 'In progress';
    case SVMasterFileAutomaticEmailStatus.error:
      return 'Error';
    case SVMasterFileAutomaticEmailStatus.stopped:
      return 'Stopped';
    case SVMasterFileAutomaticEmailStatus.ended:
      return 'Ended';
  }
}

export function getSVMasterFileAutomaticEmailStopReasonText(status: SVMasterFileAutomaticEmailStopReason): string {
  switch (status) {
    case SVMasterFileAutomaticEmailStopReason.originalEmailNotFound:
      return 'Initial email ID is not related to a real email';
    case SVMasterFileAutomaticEmailStopReason.automaticEmailNotFound:
      return 'Auto Mailer couldn’t find the last email';
    case SVMasterFileAutomaticEmailStopReason.sendingEmailFailed:
      return 'Message is empty or the email send action failed';
    case SVMasterFileAutomaticEmailStopReason.emailTemplateProcessingFailed:
      return 'Email template is missing or has an error';
    case SVMasterFileAutomaticEmailStopReason.invalidEmailParameters:
      return 'Missing email parameters';
    case SVMasterFileAutomaticEmailStopReason.invalidEmailMessageEmailId:
      return 'Email ID is incorrect';
    case SVMasterFileAutomaticEmailStopReason.generalError:
      return 'Error';
    case SVMasterFileAutomaticEmailStopReason.registered:
      return 'Supplier Registered';
    case SVMasterFileAutomaticEmailStopReason.customerInvolvement:
      return 'Validation record is under “Customer Involvement"';
    case SVMasterFileAutomaticEmailStopReason.newPhase:
      return 'Validation record is not under “In Progress” status';
    case SVMasterFileAutomaticEmailStopReason.emailInterruption:
      return 'Supplier responded to the original email. Auto-mailer stopped.';
    case SVMasterFileAutomaticEmailStopReason.noResponse:
      return 'No response';
    case SVMasterFileAutomaticEmailStopReason.stopAutomaticEmailProcessRequest:
      return 'Auto-mailer was manually stopped by user request';
  }
}

export enum AchAccount {
  yes = 'yes',
  no = 'no',
  irrelevant = 'irrelevant',
}

export function achAccountFromBoolean(isAch: boolean | null | undefined): AchAccount | undefined {
  if (!isDefined(isAch)) {
    return AchAccount.irrelevant;
  }

  if (isAch) {
    return AchAccount.yes;
  }

  return AchAccount.no;
}

export function booleanOrNullFromAchAccount(achAccount: AchAccount | null | undefined): boolean | null {
  if (achAccount === AchAccount.irrelevant) {
    return null;
  }

  return achAccount === AchAccount.yes;
}

export function stringOrNullForLocalFormat(
  localFormat: string | null | undefined,
  countryCode: string | null | undefined,
): string | null {
  if (countryCode !== COUNTRY_CODES.Mexico || !localFormat) {
    return null;
  }

  return localFormat;
}

export enum PaymentCompatabilityCheckAttribute {
  instructionType = 'InstructionType',
  countryCode = 'CountryCode',
  canUseACH = 'CanUseACH',
  account = 'Account',
  routingNumber = 'RoutingNumber',
  companyIndividualType = 'CompanyIndividualType',
  transitNumber = 'TransitNumber',
  bankCode = 'BankCode',
  accountCurrency = 'AccountCurrency',
  swift = 'Swift',
  zipCode = 'ZipCode',
  noLinkedRegistration = 'NoLinkedRegistration',
}

export const PaymentCompatabilityCheckAttributeText: Record<PaymentCompatabilityCheckAttribute, string> = {
  [PaymentCompatabilityCheckAttribute.instructionType]: 'Instruction Type',
  [PaymentCompatabilityCheckAttribute.countryCode]: 'Country Code',
  [PaymentCompatabilityCheckAttribute.canUseACH]: 'Can Use ACH',
  [PaymentCompatabilityCheckAttribute.account]: 'Account',
  [PaymentCompatabilityCheckAttribute.routingNumber]: 'Routing Number',
  [PaymentCompatabilityCheckAttribute.companyIndividualType]: 'Company Individual Type',
  [PaymentCompatabilityCheckAttribute.transitNumber]: 'Transit Number',
  [PaymentCompatabilityCheckAttribute.bankCode]: 'Bank Code',
  [PaymentCompatabilityCheckAttribute.accountCurrency]: 'Account Currency',
  [PaymentCompatabilityCheckAttribute.swift]: 'Swift',
  [PaymentCompatabilityCheckAttribute.zipCode]: 'Zip Code',
  [PaymentCompatabilityCheckAttribute.noLinkedRegistration]: 'No Linked Registration',
};

export const ASSIGNEE_UNASSIGNED_ID_VALUE = 'unassigned';
export const AUTOMAILER_STATUS_UNAVAILABLE_VALUE = 'unavailable';
export const AUTOMAILER_STATUS_UNAVAILABLE_TITLE = 'Unavailable';
export const EVIDENCE_TYPE_NOT_PROVIDED_TITLE = 'Not Provided';
export const EVIDENCE_TYPE_NOT_PROVIDED_VALUE = 'nullEvidenceType';

const NULL_ORG_ID: string = 'nullOrg';
const NULL_ORG_NAME = 'Unknown';
export const NULL_ORG = { id: NULL_ORG_ID, name: NULL_ORG_NAME, groupName: null, companyCode: null, ref: null, subdomain: null };

export const noOrgOption: ValidationSystemOrganization[] = [NULL_ORG];
